<template>
  <el-dialog :visible.sync="visible" width="1590px" class="dialog_box">
    <div slot="title" class="color_box">
      <span :class="{cur: !condition.color}" @click="setConditionData({type: 'color', value: ''})">
        <img
          src="https://file.meayan.com/important/color-all.png"
          alt=""
          title="全部"
        >
      </span>
      <span
        v-for="{value, label} in colorList"
        :key="value"
        :class="{cur: condition.color===value}"
        :style="{background: label}"
        @click="setConditionData({type: 'color', value})"
      />
    </div>
    <div class="main_box">
      <div class="search_condition">
        <div class="search_item">
          <p class="title">类型</p>
          <div class="list_box">
            <span
              v-for="{value,label} in typeList"
              :key="value"
              :class="{cur: condition.type===value}"
              @click="setConditionData({type: 'type', value})"
            >{{ label }}</span>
          </div>
        </div>
        <div class="search_item">
          <p class="title">主题</p>
          <div class="list_box">
            <span :class="{cur: !condition.schema}" @click="setConditionData({type: 'schema', value:''})">全部</span>
            <span
              v-for="{value,label} in schemaList"
              :key="value"
              :class="{cur: condition.schema===value}"
              @click="setConditionData({type: 'schema', value})"
            >{{ label }}</span>
          </div>
        </div>
        <div class="search_item">
          <p class="title">分类</p>
          <div class="list_box">
            <span :class="{cur: !condition.biz}" @click="setConditionData({type: 'biz', value:''})">全部</span>
            <span
              v-for="{value,label} in bizList"
              :key="value"
              :class="{cur: condition.biz===value}"
              @click="setConditionData({type: 'biz', value})"
            >{{ label }}</span>
          </div>
        </div>
        <div class="search_item">
          <p class="title">组件</p>
          <div class="list_box">
            <span :class="{cur: !condition.module}" @click="setConditionData({type: 'module', value:''})">全部</span>
            <span
              v-for="{value,label} in moduleList"
              :key="value"
              :class="{cur: condition.module===value}"
              @click="setConditionData({type: 'module', value})"
            >{{ label }}</span>
          </div>
        </div>
      </div>
      <div class="template_list">
        <template v-if="templateList.length">
          <div
            v-for="item in templateList"
            :key="item.id"
            class="template_box"
            :class="{cur:item.id===currentTemplate.id}"
            @click="selectTemplate(item)"
          >
            <img :src="item.cover + '&imageView2/2/w/256/h/256'" class="img" alt="">
            <p class="template_box-text">
              <span class="text_info">{{ item.name }}</span>
              <span class="show_img" title="点击查看效果图" @click.stop="showBigImg(item)">预览</span>
            </p>
            <div class="selected_box"><i class="el-icon-check" /></div>
          </div>
        </template>
        <div v-else class="empty_box">暂无内容</div>
      </div>
    </div>
    <div slot="footer">
      <a-button type="primary" :loading="saveLoading" @click="submitData">保 存</a-button>
      <a-button @click="() => { this.visible = false}">取 消</a-button>
    </div>
    <BigImg ref="bigImg"></BigImg>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex'
import BigImg from './BigImg'

export default {
  name: 'ChooseTemplate',
  components: { BigImg },
  data() {
    return {
      visible: false,
      saveLoading: false,
      pageInfo: {},
      condition: {},
      templateList: [],
      currentTemplate: {}
    }
  },
  computed: {
    ...mapState({
      typeList: state => state.dictionary.TEMPLATE_TYPE_CODE, // 模板所属类型
      schemaList: state => state.dictionary.TEMPLATE_SCHEMA_CODE, // 模板所属主题
      bizList: state => state.dictionary.TEMPLATE_BIZ_CODE, // 模板所属分类
      moduleList: state => state.dictionary.TEMPLATE_MODULE_CODE, // 模板所属组件
      colorList: state => state.dictionary.TEMPLATE_COLOR_CODE // 模板所属颜色
    })
  },
  mounted() {
    this.$store.dispatch('getDictionary', 'TEMPLATE_TYPE_CODE')
    this.$store.dispatch('getDictionary', 'TEMPLATE_SCHEMA_CODE')
    this.$store.dispatch('getDictionary', 'TEMPLATE_BIZ_CODE')
    this.$store.dispatch('getDictionary', 'TEMPLATE_MODULE_CODE')
    this.$store.dispatch('getDictionary', 'TEMPLATE_COLOR_CODE')
  },
  methods: {
    show(data) {
      this.visible = true
      this.pageInfo = data
      this.condition = {
        type: 'page'
      }
      this.currentTemplate = {}
      this.getTemplateList()
    },
    setConditionData({ type, value }) {
      if (this.condition[type]) {
        this.condition[type] = value
      } else {
        this.$set(this.condition, type, value)
      }
      this.getTemplateList()
    },
    async getTemplateList() {
      const response = await this.$axios.get('/athena/template/list', { ...this.condition })
      this.templateList = response.data
    },
    selectTemplate(data) {
      this.currentTemplate = data
    },
    async submitData() {
      if (!this.currentTemplate.id) {
        this.$message.warning('请选择模版')
        return false
      }
      const { id: templateId } = this.currentTemplate
      const { id } = this.pageInfo
      this.saveLoading = true
      const response = await this.$axios.post('/athena/page/setTemplate', { templateId, id })
      this.saveLoading = false
      if (response.code === '0000') {
        this.$message.success('设置成功')
        this.visible = false
        this.$emit('submit')
      }
    },
    showBigImg(data) {
      this.$refs.bigImg.show(data.example)
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog_box {
  ::v-deep .el-dialog {
    margin-top: 50px !important;
    .el-dialog__header {
      padding: 10px;
      border-bottom: 1px solid #ccc;
    }
    .el-dialog__headerbtn {
      top: 10px;
      right: 10px;
    }
    .el-dialog__body {
      padding: 0;
      height: 680px;
      overflow: auto;
    }
    .el-dialog__footer {
      padding: 10px;
      border-top: 1px solid #ccc;
      .ant-btn{
        margin-left: 12px;
      }
    }
  }
}

.color_box {
  @include clear-float;
  span {
    float: left;
    width: 24px;
    height: 24px;
    margin: 0 6px;
    border-radius: 12px;
    overflow: hidden;
    cursor: pointer;
    transition: all .5s;
    box-shadow: $cardShadow;
    &.cur {
      border-radius: 4px;
      border: 1px solid hsla(0, 0%, 60%, .2);
      box-shadow: none;
    }
  }
}

.main_box {
  @include clear-float;
  height: 100%;
  .search_condition {
    width: 280px;
    height: 100%;
    overflow: auto;
    float: left;
    border-right: 1px solid #ccc;
    .search_item {
      .title {
        text-align: center;
        border-bottom: 1px solid #ccc;
        line-height: 40px;
        height: 40px;
        font-size: 16px;
        color: $title1;
      }
      .list_box {
        padding: 10px 16px;
        border-bottom: 1px solid #ccc;
        @include clear-float;
        span {
          font-size: 12px;
          float: left;
          margin: 4px;
          border: 1px solid #dcdfe6;
          padding: 4px 8px;
          cursor: pointer;
          border-radius: 2px;
          &.cur {
            color: #409eff;
            border-color: #409eff;
          }
        }
      }
    }
  }
  .template_list {
    float: left;
    width: calc(100% - 280px);
    height: 100%;
    padding: 10px;
    overflow: auto;
    @include clear-float;
    .template_box {
      float: left;
      position: relative;
      width: 300px;
      height: 200px;
      margin: 10px;
      box-shadow: $cardShadow;
      overflow: hidden;
      border-radius: 4px;
      .template_box-text {
        border-top: 1px #ccc solid;
        padding-left: 6px;
        white-space: nowrap;
      }
      .selected_box {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        border-color: #409EFF #409EFF transparent transparent;
        border-style: solid;
        border-width: 20px;
        color: #fff;
        i {
          position: absolute;
          top: -14px;
          right: -16px;
          font-size: 16px;
          font-weight: bold;
        }
      }
      &.cur {
        .selected_box {
          display: block;
        }
        top: -2px;
      }
      &:hover {
        .show_img {
          display: block;
          height: 32px;
          line-height: 32px;
          color: #409EFF;
          font-size: 12px;
          font-weight: bold;
        }
      }
      .img {
        width: 100%;
        height: 168px;
        cursor: pointer;
      }
      .text_info {
        display: inline-block;
        @include text-hidden;
        width: 240px;
        height: 32px;
        line-height: 32px;
        font-size: 12px;
        color: $title2;
        font-weight: bold;
      }
      .show_img {
        float: right;
        display: none;
        transition: all .3s linear;
        color: #409eff;
        margin-right: 10px;
        cursor: pointer;
      }
    }
    .empty_box {
      height: 100%;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      font-size: 16px;
    }
  }
}
</style>
